<script>
import { useConfigStore } from "@/storex/configStore";
import { getStringAddress } from "@/lib/account/AddressFactory";

export default {
  setup() {
    const config = useConfigStore();
    return { config };
  },

  computed: {
    links() {
      return [
        { label: "Início", to: "/store" },
        { label: "Minha conta", to: "/account" },
        { label: "Sobre nós", to: "/about" },
        {
          label: "Política de privacidade",
          href: this.config.policy,
        },
        { label: "Termos de uso", href: this.config.terms },
      ];
    },
    styles() {
      if (this.$vuetify.breakpoint.smAndUp) {
        return "";
      }
      return `
        transform: translateY(-70px);
      `;
    },

    footerVisible() {
      const routes = [
        "shopping",
        "checkout",
        "appointments",
        "pets",
        "vaccines",
      ];
      return !routes.includes(this.$route.name);
    },

    storeName() {
      return this.config.storeDetails.name;
    },

    storeCnpj() {
      if (!this.config.storeDetails.cpfCnpj) {
        return "";
      }
      const s = this.$utils.cpfCnpjMask(this.config.storeDetails.cpfCnpj);
      return ` | CNPJ nº ${s}`;
    },

    storeAddress() {
      const s = getStringAddress(this.config.storeDetails);
      return ` | ${s}`;
    },
  },
};
</script>

<template>
  <v-footer color="white" padless :style="styles" v-if="footerVisible">
    <v-row
      justify="center"
      no-gutters
      style="border-top: 1px solid rgba(0, 0, 0, 0.12)"
    >
      <template v-if="$vuetify.breakpoint.smAndUp">
        <v-btn
          :key="link.label"
          class="my-2"
          color="grey darken-1"
          rounded
          small
          text
          :to="link.to"
          :href="link.href"
          :target="link.href ? '_blank' : ''"
          v-for="link in links"
        >
          {{ link.label }}
        </v-btn>
      </template>
      <v-col
        class="pt-3 pb-5 px-2 caption text-center grey--text text--darken-1"
        cols="12"
      >
        {{ storeName }}{{ storeCnpj }}{{ storeAddress }}
      </v-col>
    </v-row>
  </v-footer>
</template>
