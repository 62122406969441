export default (val, clean = false) => {
  const num = val ? val.replace(/\D/g, "") : "";
  if (num.length !== 11 && num.length !== 14 || clean) {
    return num;
  }
  if (num.length > 11) {
    return num.replace(
      /^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/,
      "$1.$2.$3/$4-$5",
    );
  }
  return num.replace(/^(\d{3})(\d{3})?(\d{3})?(\d{2})?/, "$1.$2.$3-$4");
};