import capitalize from "./capitalize";
import cepMask from "./cepMask";
import consts from "./consts";
import cpfMaskLocked from "./cpfMaskLocked";
import cpfCnpjMask from "./cpfCnpjMask";
import decimal from "./decimal";
import encrypt from "./encryptSHA";
import parseJwt from "./parseJwt";
import phoneMask from "./phoneMask";
import toTitleCase from "./toTitleCase";
import validateDocs from "./validateDocs";
import validateEmail from "./validateEmail";
import validatePassword from "./validatePassword";
import { fancyTimeFormat } from "./fancyTimeFormat";

const baseUrl = window.Smart.env.BASE_URL;

const utils = {
  fancyTimeFormat,

  capitalize(text) {
    return capitalize(text);
  },


  cepMask(text, clean) {
    return cepMask(text, clean);
  },

  cpfMaskLocked(text) {
    return cpfMaskLocked(text);
  },

  cpfCnpjMask(text) {
    return cpfCnpjMask(text);
  },

  encryptSHA(text) {
    return encrypt.hash(text, { outFormat: "hex" });
  },

  parseJwt(token) {
    return parseJwt(token);
  },

  phoneMask(val, clean) {
    return phoneMask(val, clean);
  },

  toTitleCase(text) {
    return toTitleCase(text);
  },

  validateDocs(text, type) {
    return validateDocs(text, type);
  },

  validateFullName(text = "") {
    const reg = new RegExp(/^[A-z]{2}/);
    return reg.test(text);
  },

  validateEmail(text) {
    return validateEmail(text);
  },

  validatePassword(text) {
    return validatePassword(text);
  },

  validateRecoveryCode(text = "") {
    const reg = new RegExp(/^[0-9]{6}$/);
    return reg.test(text.replace(/\D/g, ""));
  },

  cleanRecoveryCode(text = "") {
    return text.replace(/\D/g, "");
  },

  decimal(num, decimals) {
    return decimal(num, decimals);
  },

  decimalBRL(num) {
    return `R$ ${decimal(num, 2)}`;
  },

  BRL(num) {
    return `R$ ${decimal(num, 2)}`;
  },

  USD(num) {
    return `US $${decimal(num, 2, true)}`;
  },

  cents(num) {
    const s = decimal(num, 2);
    return s.replace(/\D/g, "");
  },

  decimalKG(num, decimals) {
    return `${decimal(num, decimals)} KG`;
  },

  titleCase(title) {
    return title.replace(
      /\w\S*/g,
      (s) => (s.charAt(0).toUpperCase() + s.substr(1).toLowerCase()),
    );
  },

  textFirstCase(text) {
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  },

  getPetAvatarUri(pet) {
    const uri = `${baseUrl}/api/files/-/pets-avatar/${pet.avatar}`;
    return `${uri}?${encodeURIComponent(pet.updatedAt)}`;
  },

  getPetAvatarSrc(pet) {
    if (!pet.avatar) {
      return require("@/assets/pet_placeholder.png");
    }
    return this.getPetAvatarUri(pet);
  },

  getPetAvatarSrcEdit(pet) {
    if (!pet.avatar) {
      return null;
    }
    return this.getPetAvatarUri(pet);
  },

  /**
   * Result example: `Ação` => `Acao`
   */
  removeAccents(str = "") {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  },

  /**
   * Result example: `Example & test` => `Exampletest`
   */
  removeSpecialChars(str = "") {
    return str.normalize("NFD").replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, "");
  },

  // Attrs
  consts,
};

window.$utils = utils;

export default {
  install(Vue) {
    Vue.prototype.$utils = utils;
  },
};
