<template>
  <v-app>
    <app-loading v-if="!ready" dark="dark"></app-loading>
    <v-main v-else style="background: #fafafa">
      <app-layout>
        <router-view class="child-view"></router-view>
      </app-layout>

      <v-overlay :value="$store.getters.appLoading" :opacity="0.5">
        <div class="d-flex flex-column align-center">
          <loading-circle></loading-circle>
          <span class="white--text text-uppercase font-weight-medium mt-5">
            {{ $t($store.getters.appLoadingLabel) }}
          </span>
        </div>
      </v-overlay>
    </v-main>

    <app-dialog />
    <app-messages v-if="ready" />
    <app-bottom-sheet />
    <delivery-modal />
    <map-view-modal />
    <snackbar />
    <verified-account-dialog />
    <app-footer />
  </v-app>
</template>

<script>
import { defineComponent, ref, computed } from "vue";
import schema from "@/http/apollo/schema";
import { LoadingCircle } from "@/components/styled";
import AppLayout from "@/components/common/Layout";
import AppFooter from "@/components/AppFooter";
import VerifiedAccountDialog from "@/components/common/VerifiedAccountDialog";
import AppBottomSheet from "@/components/AppBottomSheet";
import AppDialog from "@/components/AppDialog";
import AppLoading from "@/components/AppLoading";
import AppMessages from "@/components/AppMessages";
import DeliveryModal from "@/components/DeliveryModal";
import MapViewModal from "@/components/MapViewModal";
import Snackbar from "@/components/Snackbar";
import registerPushNotifications from "@/plugins/registerPushNotifications";
import { useCategoriesStore } from "./storex/categoriesStore";
import { useConfigStore } from "./storex/configStore";
import { useAccountStore } from "./storex/accountStore";
import { useItemsStore } from "./storex/itemsStore";
import { useCouponStore } from "./storex/checkout/couponStore";

export default defineComponent({
  name: "App",

  components: {
    LoadingCircle,
    AppDialog,
    AppLayout,
    AppFooter,
    AppBottomSheet,
    AppLoading,
    AppMessages,
    DeliveryModal,
    MapViewModal,
    Snackbar,
    VerifiedAccountDialog,
  },

  setup() {
    const verifiedAlert = ref(false);
    const categories = useCategoriesStore();
    const config = useConfigStore();
    const account = useAccountStore();
    const items = useItemsStore();
    const couponStore = useCouponStore();

    const ready = computed(() => categories.loaded);

    return {
      ready,
      verifiedAlert,
      categories,
      config,
      account,
      items,
      couponStore,
    };
  },

  created() {
    this.onStart();
  },

  mounted() {
    this.$events.$on("register-notifications", this.registerNotifications);
    this.$events.$on("push", this.pushNotifications);
    this.$events.$on("user-change", this.handleUserChange);
    this.$events.$on("coupon-code", this.handleCouponCode);
    this.registerNotifications();
    this.handleAccountVerification();
  },

  beforeDestroy() {
    this.$events.$off("register-notifications", this.registerNotifications);
    this.$events.$off("push", this.pushNotifications);
    this.$events.$off("user-change", this.handleUserChange);
    this.$events.$off("coupon-code", this.handleCouponCode);
  },

  computed: {
    currentPathLabel() {
      return this.config.storeTitle;
    },
  },

  watch: {
    $route(v) {
      document.title = `${v?.meta?.title ?? "Loja"} :: ${
        this.currentPathLabel
      }`;
    },

    "$store.getters.appBadge": "setAppBadge",
  },

  methods: {
    async onStart() {
      const themes = this.config.themes;
      this.$registerTheme(this, themes);
      this.config.setThemeColors({
        background_color: themes.light.background_color,
        theme_color: themes.light.theme_color,
        theme: themes.light.app_bar_theme,
      });
      await this.account.loadInitialUserData();
      await this.categories.fetchData();
      this.account.getUserDetails();
      this.$store.dispatch("shopping/getShoppingCart").then(() => {
        this.$store.dispatch("checkout/getDiscountRules");
      });

      setTimeout(() => {
        this.config.getStoreDetails().then(() => {
          this.$nextTick(() => {
            const label = this.config.storeTitle;
            document.title = `Loja :: ${label}`;
          });
        });
      }, 1000);
    },

    handleAccountVerification() {
      if (this.verifiedAlert) {
        return;
      }
      if (this.$route.query?.verified === "1") {
        this.verifiedAlert = true;
        this.account.verifiedAccountDialog = true;
      }
    },

    handleUserChange() {
      this.items.resetItems();
    },

    handleCouponCode(value) {
      this.couponStore.setCouponCode({ value, skipValidations: true });
    },

    registerNotifications(remove) {
      if (window.isNativeApp) {
        registerPushNotifications(this, remove);
        return;
      }

      if (!window.Notification) {
        return;
      }

      if (remove) {
        registerPushNotifications(this, true);
        return;
      }

      if (!this.$store.getters.isAuthenticated) {
        return;
      }

      const notGranted = Notification.permission !== "granted";
      const notDenied = Notification.permission !== "denied";

      if (notGranted && notDenied) {
        this.notificationAction();
      } else {
        registerPushNotifications(this);
      }
      this.pushNotifications();
    },

    notificationAction() {
      Notification.requestPermission((status) => {
        if (status === "denied") {
          window.$snackbar.show({
            text: "As notificações estão bloqueadas",
            action: { text: "OK" },
          });
        }
        registerPushNotifications(this);
      });
    },

    setAppBadge(v) {
      if (navigator.setAppBadge) {
        navigator.setAppBadge(v).catch(() => null);
      }
    },

    async pushNotifications() {
      const currentStore = this.config.currentStore;
      const isAuthenticated = this.$store.getters.isAuthenticated;
      if (currentStore && isAuthenticated) {
        try {
          const { data } = await this.$apollo.query({
            query: schema.user.UNREAD_NOTIFICATIONS,
          });

          this.$store.commit("appBadge", data?.total?.unreadNotifications ?? 0);
        } catch (e) {
          // fail
        }
      }
    },
  },
});
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.2s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

@media only screen and (max-width: 600px) {
  .v-toolbar__content {
    padding: 4px 0;
  }
}

.smart-item-description img {
  max-width: 100%;
}

.smart-item-description section,
.smart-item-description table,
.smart-item-description div {
  width: 100% !important;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

.checkout-next-button {
  display: flex;
  justify-content: space-between;
}

.bounce-enter-active {
  animation: bounce-in 0.3s;
}
.bounce-leave-active {
  animation: bounce-in 0.3s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}
</style>
